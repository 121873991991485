@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.............................Navbar CSS....................*/
.navbar {
  /* position: fixed; */
  top:0;
  left:0;
  width:100%;
  height:70px;                        /*CSS Changed */
  background: #ffffff;
}
.navBarNavigation {
  display:flex;
  align-items:center;
  height:100%;
  padding: 0 1rem;
  flex:1 1;
}
.navIcon img {
  height:20px;
  width:20px;
}
.navBarNavigation a{
  font-family: "Karla";
  color: #8c0204 !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  text-decoration:none;
  float:left;
}
.navBarNavigation img{
  
  width: 50px;
  height: 50px;
  float:left ;
  
}
.navBrand {
  margin-left:0.5rem;
}

.seperater{
   flex:1 1;
}
.navLogo {
  width: 40.2px;
  height: 21.7px;
  float:left ;
}

.navbar {
  flex-shrink: 0;
}
/*.............................Navbar CSS....................*/

/*.............................Wrappers....................*/
#wrapper {
  position:relative;
  flex: 1 1
}

#main-wrapper {
  height: 100vh;
  display: -webkit-flex; /* Safari */ /* Safari 6.1+ */
  display: flex;
  flex-flow: column;
}
.mainContent {
  position:relative;
  left:250px;
  min-width:50%;
  /* height:100% !important; */
  background-color: #fbfbfb ;
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  /* padding:0% 5% !important; */
  align-items: stretch;
  flex:1 0 auto;                   
}
/*.............................Wrappers....................*/

/*.............................Toggle Button....................*/
.toggleButton {
  display:flex;
  flex-direction: column;
  height:24px;
  width:30px;
  background:transparent;
  border:none;
  padding:0;
  cursor:pointer;
  box-sizing: border-box;
  justify-content: space-around;
}
.toggleButton:focus {
  outline: none;
}

.toggleButtonLine {
  width:30px;
  height:2px;
  background-color: gray;
}

/*.............................Toggle Button....................*/

/*.............................SideBar....................*/
.sidebarWrapper {
  
  position: absolute;
  width: 90%;
  max-width: 250px;
  height: 100%;
  z-index:100;
  background: #f7f7f7;

}
.sidebarWrapper ul {
  height: 100%;
  list-style: none;
  margin:0;
  padding:0;
}

.sidebarWrapper .spacer{
  width: 55%;
  height:2px;
  background-color: #9b9b9b;
  align-self: end;
  float: right;


}

.sidebarNav li.active a{
  color: #052d99 !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.sidebarNav li.active img{
  -webkit-filter: invert(13%) sepia(96%) saturate(3394%) hue-rotate(225deg) brightness(81%) contrast(104%) !important;
          filter: invert(13%) sepia(96%) saturate(3394%) hue-rotate(225deg) brightness(81%) contrast(104%) !important;
}

.sidebarNav li.active {
  text-decoration: none;
  height: 24px;
  font-family: Karla;
  font-size: 20px !important;
  letter-spacing: -0.09px;
  background-color: rgba(5,45,153,0.07);
  border: solid #052d99;
  border-width: 0px 6px 0px 0px;

}

.sidebarWrapper li{
  width:100%;
  height: 65px !important;
  padding-top: 20px !important;
  padding-bottom: 25px !important;
  padding-left:40px;
  color: #4a4a4a !important;
}

.sidebarWrapper li a{
  text-decoration: none;
  /* height: 24px; */
  font-family: Karla;
  font-size: 16px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #4a4a4a;
  margin-left:25px;
}
.collapsible {
  position: "absolute";
   top: 86;
   right:0;
   left: 0;
   z-index: 1000;
}
/*..................................SideBar....................*/

/*.............................Page Footer....................*/
.pageFooter {
  display:flex;
  /* margin-left:250px !important; */
  width:100%;
  height:60px !important;   
  background: #f5f5f5 ;
  flex-shrink: 0!important;          /* To BE corrected */
}
/*.............................Page Footer....................*/

/*...................Input TextBox CSS....................*/
.color{
  background-color: #fbfbfb !important;
}
.inputGroup{
  position:relative;
  width:100%;
}
.inputGroup input{
  height:40px;
  width:100%;
  box-sizing:border-box;
  outline:none;
  border:1px solid #000;
  text-indent: 20px;
}
.inputGroup span{
  position: absolute;
  top:12px ;
  left:50px;
  -webkit-transition:0.5s;
  transition:0.5s;
  background: #fbfbfb;
  color:#5d5d5d!important;
}
.inputText {
  background: transparent !important;
  border: solid 1px #4a4a4a !important;
  border-radius:  2px !important;
  
}
/* .inputGroup input:not(input[value=""]) ~ span {
  top: -5px !important;
  left:8px !important;
  font-size:10px;
  padding-left:5px;
  padding-right:5px;
} */
.inputGroup input[fieldCheck=filled] ~ span {
  top: -5px !important;
  left:8px !important;
  font-size:10px;
  padding-left:5px;
  padding-right:5px; 
}
.inputGroup input:disabled ~ span {
  top: -5px !important;
  left:8px !important;
  font-size:10px;
  padding-left:5px;
  padding-right:5px;
}
input:disabled {
  cursor: not-allowed;
}
.inputGroup input:disabled {
  border: solid 2px #417505 !important;
  background-color: #dad9d9 !important;
  text-indent: 20px;
}
.spanTop{
  top: -5px !important;
  left:8px !important;
  font-size:10px;
  padding-left:5px;
  padding-right:5px; 
}
.spanMiddle{
  top:12px !important;
  left:50px !important;
  padding-left:5px;
  padding-right:5px;
}
.valid{
  border: solid 2px #417505 !important;
}
.invalid{
  border: solid 2px #8c0204 !important;
}
.activeFocus{
  border: solid 2px #052d99 !important;
}

/*...................Input TextBox CSS....................*/

/*...................Form Control CSS....................*/
.form-control:disabled{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}
.form-control:disabled::-webkit-input-placeholder{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}
.form-control:disabled::-moz-placeholder{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}
.form-control:disabled:-ms-input-placeholder{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}
.form-control:disabled::-ms-input-placeholder{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}
.form-control:disabled::placeholder{
  background-color: #dad9d9 !important;
  color:inherit !important;
  opacity: 1;
  cursor: not-allowed;
}


.form-control[readonly] {
  background-color:transparent !important;
  opacity: 1;
}
/*...................Form Control CSS....................*/

/*...................User Verify Page CSS....................*/
.inputRowUserVerify {
  font-size: 30px !important;
}

.inputRowContactPage {
  font-size: 24px !important;
}

.inputRowUserVerifyBody {
  font-size: 30px !important;
  color:#dad9d9!important;
}
/*...................User Verify Page CSS....................*/

/*...................ID CSS for background Images in input Text boxes....................*/

#name {
  background: url(/static/media/card.92561f9b.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#name:disabled {
  background: url(/static/media/card.92561f9b.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#year {
  background: url(/static/media/date.8ba117f6.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#year:disabled {
  background: url(/static/media/date.8ba117f6.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#search {
  background: url(/static/media/search.e98028aa.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#emailId{
  background: url(/static/media/envelope.eb6255f3.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}
#emailId:disabled{
  background: url(/static/media/envelope.eb6255f3.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#address{
  background: url(/static/media/mapPointer.508148bd.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#address:disabled{
  background: url(/static/media/mapPointer.508148bd.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#bankName{
  background: url(/static/media/bank.75836064.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#mobile{
  background: url(/static/media/mobile.88debca4.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#mobile:disabled{
  background: url(/static/media/mobile.88debca4.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#homephone{
  background: url(/static/media/homephone.d5d3f6d5.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}
#homephone:disabled{
  background: url(/static/media/homephone.d5d3f6d5.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#fax{
  background: url(/static/media/fax.234ea644.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#fax:disabled{
  background: url(/static/media/fax.234ea644.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#occupation{
  background: url(/static/media/occupation.cfd0cc43.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  /* text-indent: 50px; */
}

/* #occupation{
  background: url('assets/img/occupation.svg') no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
} */

#otherDoc{
  background: url(/static/media/noundocuments.dce842fe.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  text-indent: 50px;
}

#otherDoc:disabled{
  background: url(/static/media/noundocuments.dce842fe.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#employer{
  background: url(/static/media/employer.6512226f.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  text-indent: 50px;
}

#employer:disabled{
  background: url(/static/media/employer.6512226f.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#netMonthly{
  background: url(/static/media/netMonthly.2d1efaba.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  text-indent: 50px;
}

#netMonthly:disabled{
  background: url(/static/media/netMonthly.2d1efaba.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}

#assessable{
  background: url(/static/media/assessable.2760e687.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  text-indent: 50px;
}
#homeCurrency {
  background: url(/static/media/assessable.2760e687.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  text-indent: 50px;
}

#homeCurrency:disabled {
  background: url(/static/media/assessable.2760e687.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 30px 20px !important;
  background-position-x: 10px !important;
  background-color: #dad9d9 !important;
  cursor: not-allowed;
}

#paymentAmount{
  border:none!important;
  border-bottom:1px solid !important;
}

#assessable:disabled{
  background: url(/static/media/assessable.2760e687.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  cursor: not-allowed;
}
#notifyCheckBox {
  border: none !important;
  background: none !important;
}
#notifyText {
  border: none !important;
  background: none !important;
}
#notifyCheckBox_next {
  border: none !important;
  background: none !important;
}
#notifyText_next {
  border: none !important;
  background: none !important;
}

#typeNumber {
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}
#yearOfAssesment {
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}
#building{
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#building:disabled{
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#street{
  background: url(/static/media/street.1f231a9a.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#street:disabled{
  background: url(/static/media/street.1f231a9a.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}


#floor{
  /* background: url('assets/img/stairs.svg') no-repeat !important; */
  background: url(/static/media/stairsfloor.c61754b0.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#floor:disabled{
  background: url(/static/media/stairsfloor.c61754b0.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#apartment{
  background: url(/static/media/apartment.1a9486ff.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#apartment:disabled{
  background: url(/static/media/apartment.1a9486ff.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#block{
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}
#block:disabled{
  background: url(/static/media/building.c3467b56.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#postal{
  background: url(/static/media/postal.a828273b.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}
#postal:disabled{
  background: url(/static/media/postal.a828273b.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #dad9d9 !important;
  text-indent: 50px;
  /* cursor: not-allowed; */
}

#amountToTransfer{
  background: url(/static/media/money.2634d37b.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #fbfbfb !important;
  text-indent: 50px;
  
}
/*...................ID CSS for background Images in input Text boxes....................*/
/* .inputText::placeholder {
  font-family: Karla;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.69px;
  color: #9f9f9f;

}
input:invalid {
  outline: red !important;
} */
.inputDate {
  background: transparent !important;
  border:none!important;
  height:20px;
  width:20px;
}
/* 
input:disabled {
  color: rgb(84, 84, 84);
  cursor:  not-allowed !important;
<<<<<<< HEAD
} */
/*...................File type input CSS....................*/


/* input[type="file"] {
>>>>>>> 0e15340069f44bc50e53de6cd78fa51df3da508d
	display: inline-block;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 99;
	margin-top: 10px;
	cursor:pointer;
} */
/* .inputFile input[type="file"]{
  display: inline-block;
	opacity: 1 !important;
	position: relative;
	width: 85%;
	z-index: 99;
	margin-top: 10px;
	cursor:pointer;
} */

/* input[type="file"] {
width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
} */

.custom-file-upload {
	position:relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 15px;
  background: transparent url(/static/media/upload.0746cdd8.svg) left center no-repeat;
  background-size: 20px 20px;
  background-position-y: 10px;
  width:91%;
  color: #052d99 !important;
  font-weight: normal !important;
	border:none !important;
  text-align:center;
  font-size: 16px !important ;
  max-width: 300px;
  max-height: 30px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-left: 30px !important;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: '369px' !important;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  border: 1px #4a4a4a solid;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  /* max-width:20px; */
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fbfbfb !important;
  border: 1px solid  #4a4a4a ;
  border-style: solid !important;
  border-radius: 0px !important;
  text-align: center;
  width: 380px;
  height: 40px !important;
  padding-top: 11px !important;
}


.custom-file-label::after {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right:360px !important;
  bottom: 0 !important;
  z-index: 3 !important;
  display: block !important;
  height: calc(1.5em + .75rem) !important;
  padding: .375rem .75rem !important;
  line-height: 1.5 !important;
  color: #8b0304 !important;
  content: "Browse.." !important;
  background-color: #fbfbfb  !important;
  border: solid 1px!important;
  border-right: inherit 1px !important;
  text-indent: '30px'!important;
  border-radius: 0px !important;
  width: 80px;
  height: 38px !important;
  padding-top: 10px !important;
}


.docLabel{
  padding-left: 160px
}
/*...................File type input CSS....................*/


/*..............................test................................*/

.input-container {
  margin: 3em auto;
  max-width: 380px;
  background-color: #ffffff;
  border: 1px solid #9f9f9f;
  border-right: inherit 1px ;
  text-indent: '30px';
  border-radius: 0px !important;
  display: flex;
  margin: 0px;
  cursor: pointer;
}
input[type='file'] {
  display: none;
}

.browse-btn {
  background: #ffffff;
  color: #8b0304;
  min-height: 35px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: block ;
  border: solid 1px;
  border-radius: 0px;
  font-size: 12px;
}

.file-info {
  display: block;
  color: #9f9f9f;
  /* background: #ffffff; */
  font-size: 12px;
  overflow-x: scroll !important;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
  text-align: center;
  width: 310px;
  -webkit-scrollbar-button:'10px';
} 



/* ::-webkit-scrollbar {
  width: 12px;
  height: 10px;
} 

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px black; 
  border-radius: 0px;
  
  height: 20px;
}

 ::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px black; 
  height: 50px;
} */

/*..............................test........................................*/

/*...................Profile Pic Upload CSS......................*/

.custom-pic-file-upload {
	position:relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-right: 80px;
  background: transparent url(/static/media/picPlaceHolder.2fd183c2.svg) left center no-repeat;
  background-size: 60px 60px;
  background-position-y: 14px;
  letter-spacing: -0.12px;
  width:70%;
  color: #052d99 !important;
  font-weight: normal !important;
	border:none !important;
  text-align:center;
  font-size: 16px !important ;
}
.custom-pic-file-uploadCrop {
	position:relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-right: 20px;
  background: transparent url(/static/media/picPlaceHolder.2fd183c2.svg) left center no-repeat;
  background-size: 60px 60px;
  background-position-y: 14px;
  letter-spacing: -0.12px;
  width:70%;
  color: #052d99 !important;
  font-weight: normal !important;
	border:none !important;
  text-align:center;
  font-size: 16px !important ;
}

.custom-pic-file-uploa-update {
	position:relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 38px;
  padding-bottom: 30px;
  padding-right: 191px;
  letter-spacing: -0.12px;
  width:70%;
  color: #052d99 !important;
  font-weight: normal !important;
	border:none !important;
  text-align:center;
  font-size: 16px !important ;
}

.custom-pic-file-uploa-updateCrop {
	position:relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 38px;
  padding-bottom: 30px;
  padding-right: 158px;
  letter-spacing: -0.12px;
  width:70%;
  color: #052d99 !important;
  font-weight: normal !important;
	border:none !important;
  text-align:center;
  font-size: 16px !important ;
}
.imgProfile{
  border-radius:50px !important;
}
/*...................Profile Pic Upload CSS......................*/

/*...................BreadCrumb CSS......................*/

.breadcrumbContainer{
  margin:0% 5% !important;
}
.breadcrumbContainerColumn{
  margin:0 !important;
  padding:0 !important;
}

.breadcrumb{
  background-color: transparent !important;
  font-size: 14px; 
  padding-left:0!important;
  margin:0 !important; 
  color:#052d99!important;
}
.breadcrumbItem{
  color:#052d99!important;
  padding-left:0!important;
}
.breadcrumbItem::before{
  content:">"!important;
  padding-right:.1rem!important;
  padding-left:.1rem!important;
}
.breadcrumbItem.initial::before{
  content:""!important;
  padding-right:.1rem!important;
  padding-left:.1rem!important;
}
.breadcrumbItem.active{
  color:#4a4a4a!important;
}
/*...................BreadCrumb CSS......................*/

/*...................Different Sections of Page......................*/
body{
  line-height:1 !important;
}
.pageHeading{
  font-family: Karla !important;
  font-size: 40px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 0.66 !important;
  letter-spacing: -0.22px !important;
  color: #4a4a4a !important;
  /* .... */
  margin:0% 5% !important;     

}
.pageHeadingColumn{
  margin:0 !important;
  padding:0 !important;;
}
.pageHeadingColumn li{
  font-size: 18px !important;
  margin:10px 0px !important;
}

.section {
  margin:0% 5% !important;
}
.sectionHeading {
  font-family: Karla;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.26;
  letter-spacing: -1.1px;
  color: #4a4a4a;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-left:0 !important;
  padding-right:0 !important;
}

.lineSpacer {                          /* Not Used Anymore */
  margin-top:13px;
}

.inputField { 
  position: relative;
  padding-left:0px !important;
  margin-bottom: 25px; 
}

.inputRow {
  max-width:950px;
  width:90%;
}

.inputRow label {
  font-family: Karla;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.48px;
  color: #4a4a4a;
  margin:0;
}

.inputRow select {
  border:none;
  font-family: Karla;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.74px;
  color: #052d99;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent no-repeat right;
  background-size: 15px 25px ;
  outline: none;
  background-position-x:100% !important;
  padding:0;
  padding-right: .8rem;
}
.inputRow select:hover{
  text-decoration: underline ;
}
/*...................Different Sections of Page......................*/

/*...................HomePage Components CSS(Priya)......................*/

.homeTable:hover {
  background-color:rgba(140, 2, 4, 0.05) !important;
}

.homeButtonDisplay{
  display: none ;
}
.homeTable:hover .homeButtonDisplay{
  display:inline;
}


.homeRow {
  margin-left: -15px !important;
}

.homeTransButton {
  padding-Left : 0px;
}

.homeLabel {
  color: #4a4a4a !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
  text-indent: 50px !important;
}

.userHead {
  margin-Bottom:0px !important;
}

.border-bottomHome {
  border-bottom: 1px solid red!important;
}
.homeLabel::after {
  content:"Transactions";
  width:190px !important; 
  text-align:left !important; 
  border-bottom: 1px solid #000 !important;
  line-height:0.1em !important; 
  margin:10px 0 20px !important;
}

.homeSelect{
  /* font-size: 20px !important; */
  padding-right: 2rem !important;
  padding-top: 30px !important;
  background-position-y: 100% !important;
  background-position-x: 70% !important;
}

.homeSelect:checked{
  font-size: 20px !important;
}
.inputRow .document {
  border:none;
  display: inline-block;
  font-family: Karla;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #052d99;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent url(/static/media/selectArrow.f54ea3d2.svg) no-repeat right;
  background-size: 15px 25px ;
  outline: none;
  background-position-x:100%;
  padding:0;
  overflow: hidden;
  padding-right: 1rem;
  padding-top: 0px;
}

.checkColor {
  border: solid 2px #1c0087;
}
/*...................HomePage Components CSS(Priya)......................*/

/*...................Select Box Background CSS......................*/
.countrySelect {
  padding-left:40px !important;
  background: transparent url(/static/media/globe.fe114e78.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
}
.dateSelect {
  padding-left:40px !important;
  background: transparent url(/static/media/date.8ba117f6.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
}

.documentSelect {
  padding-left:40px !important;
  background: transparent url(/static/media/noundocuments.dce842fe.svg) no-repeat right;
  background-position-x:0%;
  background-size: 25px 25px ;
  background-position-y: 33%;
}

.transactionAmount{
  padding-left:40px !important;
  background: transparent url(/static/media/transactionAmount.3ba4f3a3.svg) no-repeat right;
  background-position-x:3%;
  background-size: 30px 30px ;
}
.genderSelect {
  padding-left:40px !important;
  background: transparent url(/static/media/person.99a89eed.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
  background-position-y:5%;
}

.nricSelect {
  padding-left:40px !important;
  background: transparent url(/static/media/card.92561f9b.svg) no-repeat right;
  background-position-x:0%;
  background-size: 30px 30px ;
  background-position-y:5%;
}

.transBeneficiary {
  padding-left:40px !important;
  background: transparent url(/static/media/person.99a89eed.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
  background-position-y:5%;
}
.transCurrency{
  padding-left:40px !important;
  background: transparent url(/static/media/currency.373d3162.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
  background-position-y:5%;
}
.executionDate{
  padding-left:40px !important;
  background: transparent url(/static/media/date.8ba117f6.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
  background-position-y:5%;
}
.nounhome {
  padding-left:40px !important;
  background: transparent url(/static/media/noun-home.1f9efd26.svg) no-repeat right;
  background-position-x:3%;
  background-size: 25px 25px ;
  background-position-y:70%;
}

.nounTax {
  padding-left:40px !important;
  background: transparent url(/static/media/noun-home.1f9efd26.svg) no-repeat right;
  background-position-x:3%;
  background-size: 25px 25px ;
  background-position-y:20%;
}

.idNumber {
  padding-left:40px !important;
  background: transparent url(/static/media/card.92561f9b.svg) no-repeat right;
  background-position-x:3%;
  background-size: 30px 25px ;
  background-position-y:20%;
  margin-right:40px !important;
}
.transferMethod {
  padding-left:40px !important;
  background: transparent url(/static/media/exchange.c851f099.svg) no-repeat right;
  background-position-x:3%;
  background-size: 30px 25px ;
  background-position-y:20%;
  margin-right:40px !important;
}
.nounflag {
  padding-left:40px !important;
  background: transparent url(/static/media/noun-flag.54fc492e.svg) no-repeat right;
  background-position-x:3%;
  background-size: 25px 25px ;
  background-position-y:70%;
}
.paymentType {
  padding-left:40px !important;
  background: transparent url(/static/media/paymentType.d1047e4b.svg) no-repeat right;
  background-position-x:0%;
  background-size: 35px 35px ;
  background-position-y:5%;
}
/*...................Select Box Background CSS......................*/

/*.........................Page Button CSS..........................*/
.pageButton.secondary  {
  border-radius:32px !important;
  width:100px;
  background:transparent !important;
  font-family: Karla;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color:#052d99 !important;
  border:1px solid #052d99 !important;
  margin: 15px 10px ;
}
.pageButton.primary  {
  border-radius:32px !important;
  width:100px; /* 150px*/
  background:#9b9b9b;
  font-family: Karla;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color:#ffffff !important;
  border:1px solid #9b9b9b !important;
  margin: 15px 10px !important;
}

.pageButton.userprimary  {
  border-radius:32px !important;
  width:100px; /* 150px*/
  background:#9b9b9b;
  font-family: Karla;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color:#ffffff !important;
  border:1px solid #9b9b9b !important;
  margin: 15px 10px !important;
  margin-left: 0px !important;
}

.activeState{
  background:#8b0305!important;
}

.btn-circle {
  width: 25px !important;
  height: 25px !important;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  align-items: center !important;
  font-size: 12px !important;
  line-height: 1 !important;
  /* background-color: rgb(175, 171, 171) !important; */
  background-color: #8b0305 !important;
}

.btn-circledoc {
  width: 25px !important;
  height: 25px !important;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  align-items: center !important;
  font-size: 12px !important;
  line-height: 1 !important;
  background-color: #77c077!important;
}

/*.........................Page Buttons CSS..........................*/

/*................................CSS Components(Priya) ..............................*/
.forexLoginInput {
  border-width: 0px 0px 1.3px 0px !important;
  border-color: #8e8e8f !important;
  border-radius: 0px !important;
  height: 48px !important;
  margin-top: 16px;
}

.forexSubtitle {
  color: #9b9a9b !important;
  font-size: 12px;
}

.forexSubtitleErr {
  color: #f00 !important;
  font-size: 12px;
}


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* .btn-forexSecondary {
  background-color: #9b9a9b !important;
} */

a[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.userButton:hover:focus:active {
  background-color: #6d0000 !important;
}

.logoImgText {
   /* background-color: rgb(119, 121, 122); */
  padding: 16px 32px;
  position: absolute;
  top: 67%;
  left: 80% ;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 683px;
  height: 221px;
  font-size: 35px;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: extra-expanded;
  line-height: 1.24;
  /* letter-spacing: -0.35px; */
  color: #8b0304;
  letter-spacing: 2px;
  display: inline-block;
}
.home-circle {
  width: 40px !important;
  height: 40px !important;
  padding: 6px 0px !important;
  border-radius: 25px !important;
  text-align: center !important;
  align-items: center !important;
  font-size: 20px !important;
  line-height: 1 !important;
  background-color:#6d0000 !important;
}

.logoImgText2{
  padding: 16px 32px;
  position: absolute;
  top: 80%;
  left: 80%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 683px;
  height: 221px;
  font-weight: 300 !important;
  font-size: 35px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: -0.35px;
  color: #8b0304;
}

.forgetPwd {
  color: #51cbce !important;
  text-decoration: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 15px;
  cursor:pointer;
}

.logoImgTextLogin {
  font-size: 35px;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: extra-expanded;
  line-height: 1.24;
  letter-spacing: -0.35px;
  color: #8b0304;

}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #f1f1f1 !important;
  border: 0;
}

.logoutColor:hover { 
  color: #51cbce; 
}
.btn-roundMyInfo{
  border-width: 1px;
  border-radius: 30px;
  padding-right: 23px;
  padding-left: 23px;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #8b0304 !important ;
  border-color: #8b0304 !important;
 
}

element.style {
  cursor: text;
  background-color:transparent !important;
  margin-top: 0px;
}

ß.form-control[readonly] {
  background-color:#dad9d9 !important;
  opacity: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5d5d5d !important;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px !important;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-controlType {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding:0%  !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5d5d5d !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: url(/static/media/occupation.cfd0cc43.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color:transparent !important;
  background-clip: padding-box;
  border: solid 1px #5d5d5d !important;
  border-radius: 0px !important;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-controlType:disabled {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding:0%  !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: url(/static/media/occupation.cfd0cc43.svg) no-repeat !important;
  background-position: left center !important;
  background-size: 25px 25px !important;
  background-position-x: 5px !important;
  background-color: #9f9f9f !important;
  background-clip: padding-box;
  border: solid 1px #4a4a4a !important;
  border-radius: 2px !important;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: not-allowed;
}


.form-controlType input{
  text-indent: 36px !important;
}


.form-controlType:focus{
  color : #495057;
  background-color:transparent !important;
  border-color:transparent !important;
  box-shadow: 0px 0px 0px 0.2px #f1f1f1 !important; 
}


/* background: url('assets/img/card.svg') no-repeat !important;
background-position: left center !important;
background-size: 30px 30px !important;
background-position-x: 5px !important; */
/*........................date............................*/
input[type="date"]:before {
  color: #052d99 !important;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  border: none !important;
  padding-bottom:'0px !important';
  padding-right : '0px';
  margin-right : '0px';
}
input[type="date"]{
  color: #052d99 !important;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  border: none !important;
  padding-bottom:'0px !important';
  padding-right : '0px';
  margin-right : '0px';
}

input[type="date"]:before {
  color: #052d99 !important;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  border: none !important;
  padding-bottom:'0px !important';
  padding-right : '0px';
  margin-right : '0px';
}
input[type="date"]:disabled {
  color: #052d99 !important;
  background-color: #fbfbfb !important;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  cursor: not-allowed;
  border: none !important;
  padding-bottom:'0px !important';
  padding-right : '0px';
  margin-right : '0px';
}
input[type="date"]:focus:before {
  content: '' !important;
  border: none !important;
  padding-bottom:'0px !important';
  padding-right : '0px';
  margin-right : '0px';
}
/*................................CSS Components(Priya) ..............................*/

/*........................................Table CSS...................................*/
.tableHeading{
  font-family: Karla;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  color: #585859 !important;
  text-align: center;
}

.tableContent{
  font-family: Karla;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.06px;
  color: #4a4a4a;
  text-align: center;
}
.tableContent tr:nth-of-type(odd){
  background-color:#fbfbfb !important;
}
.tableContent tr:nth-of-type(even){
  background-color:#f7f6f6 !important;
}
.tableHeading img{
  height:25px;
  width:25px;
}
.tableContent tr:hover{
  background-color:#eae3e3 !important;
}
.tableHeading span{
  margin-left: 0px;
  color:#052d99 ;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: right !important;
  background:transparent url(/static/media/add.f6491141.svg) no-repeat left center;
  background-size:25px 25px;
  background-position-x: 0 !important;
  padding-bottom:10px;
  padding-top:10px;
  padding-left:30px;

}

.tableHeading p{
  color:#052d99 ;
}

.tableContent img {
  height:25px;
  width:25px;
}

.completed {
  color: #417505;
}

.failed {
  color: #d0021b;
}

.inProgress {
color: #ffbf00;
}
/*........................................Table CSS...................................*/


/*.............................Modal CSS....................*/
.modalHeader span{
  font-family: Arvo;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  color: #4a4a4a;
  padding-left:10px;
}
.modalHeader .headerImage {
  float:left;
  width:30px;
  height:30px;
}
.modalHeader .toggleImage {
  float:right;
  margin-top:10px;
  width:10px;
  height:10px;
}
.modalSection {
  background: url(/static/media/bgTransaction1.78263a98.svg) right bottom no-repeat,url(/static/media/bgTransaction2.c4789eeb.svg) right bottom no-repeat;
  background-size: 50% 90%;
}
.modalContent{
  
  font-family: Karla !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal;
  line-height: 1.33 !important;
  letter-spacing: -0.11px !important;
  color: #4a4a4a !important;
}
.modalContent .modalMessage {
  padding-top:15px !important;
  padding-left:15px !important;
}
.modalContent .modalTransactionDetails {
  font-family: Karla;
  font-size: 10px;
  line-height: 1.38;
  letter-spacing: -0.07px;
  color: #4a4a4a;
  padding-top:15px !important;
  padding-left:15px !important;
}
.modalContent .modalBankDetails {
  font-family: Karla;
  font-size: 10px;
  line-height: 1.38;
  letter-spacing: -0.07px;
  color: #7e7e7e;
  padding-top:15px !important;
  padding-left:15px !important;
}
/*......................................Modal CSS...........................*/

/*...................................Check Box..............................*/

.containerCheck {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 16px ;
  cursor: pointer;
  padding-right: 10px !important;
  font-size: 14px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  
}

.containerCheck input:disabled ~ .checkmark{
  background-color: #dad9d9;
  cursor: not-allowed;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #eee;
  border: solid 1px #1c0087;
   
}

.containerCheck:hover input ~ .checkmark {
  background-color: #ccc; 
}

.containerCheck input:checked ~ .checkmark {
  background-color: #1c0087;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  left: 3px;
  top: 0px;
  bottom:2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(36deg);
}
/*...................................Check Box.................................*/

/*...................................Radio Button..............................*/
.containerRadio {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  padding-right: 10px !important;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkRadio {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #eee;
  border-radius: 50%;
  border: solid 1px #1c0087;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .checkmarkRadio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .checkmarkRadio {
  background-color: #fbfbfb;
}

.containerRadio input:disabled ~ .checkmarkRadio {
  background-color: #dad9d9;
  cursor: not-allowed;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkRadio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .checkmarkRadio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmarkRadio:after {
 	top: 2px;
	left: 2px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #1c0087;
}
/*...................................Radio Button..............................*/

/*...................................CSS(Priya)................................*/
.documentPage{
  margin-bottom:15px !important;
}
.footer{
  background-color: #fbfbfb !important;
}
.temp span{
  left:20px !important;
}

.documentButton{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/*...................................CSS(Priya)................................*/

/*...................................Screen Controller CSS................................*/
@media (min-width: 768px){
  .toggleButtonDiv {
    display:none;
  }
  .collapsible {
    display:none;
  }
}
@media (max-width: 768px){
    .navbar .navLogout {
        display:none;
      }
    .sidebarWrapper {
      display: none;
    }
    .mainContent {
      left :0;
    }
    .pageFooter{
      margin-left:0 !important;
      background: #f1f1f1 !important;
    }
    .pp_div {
      padding: 0 30px !important;
    }
    .tnc_div {
      padding: 0 30px !important;
    }
    .tnc_list {
      padding-left: 10px !important;
    }
}

/*...................................Screen Controller CSS................................*/


/*....................................Model CSS.....................................*/
.navModal {
  justify-Content :'flex-start !important'; 
}

/*....................................Contact US.....................................*/

.selectContact {
  border: 1px solid #ced4da !important;
  padding: .375rem .75rem !important;
  border-radius: .25rem !important;
  -webkit-appearance: menulist-button !important;
  color: #5d5d5d !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: normal !important;
  
}

.selectContactselect{
  font-style: normal !important;
}

.selectContact:hover{
  text-decoration: none !important;
}

.customDropdown{
  position:absolute;
  background-color:#f1f1f1;
  margin-top: 0;
  width:300px;
  overflow:auto;
  z-index:1;
  right:25px;
  top:25px;
  list-style: none;
  border-radius: 10px;
  box-shadow: 6px 6px 10px -4px rgba(0, 0, 0, 0.15);
  padding: 10px;
  max-height: 40vh;
}

.customDropdown li:last-child{
  border: 0px;
}

.customDropdown li {
  float: none;
  margin: 10px 0px;
  padding: 5px 5px 10px 5px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #cccccc;
  font-size: 12px;
  font-family: karla;
  text-align: justify;
}
.customDropdown span {
  float:right;
  margin:0px 0px 2px 5px;
  padding:3px;
  color:#ffffff;
  font-weight:bold;
  background-color:#969799;
  border-radius:5px
}

.notificationBadge {
  background-color:#8b0204; 
  border-radius:50%; 
  color:#ffffff; 
  font-size:10px;
  padding:2px 5px;
  position: absolute;
  left: 38px
}
 
.displayNone {
  display:none;
}

.selectContainer {
  display:inline;
  margin-right: 10px;
}
.selectContainer input[type="checkbox"] { display: none; }
.selectContainer input[type="checkbox"] + label {
  font-family: Karla;
  background: transparent no-repeat right;
  font-size:14px !important;
  border:1px solid #042d99;
  border-radius:20px;
  padding: 5px;
  cursor:pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.selectContainer input[type="checkbox"]:checked + label {
  background:#8b0103;
  color:white;
}


